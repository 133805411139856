import React from 'react'

// layouts
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from '../../style'

// Sections
import Header from '../../experiencia/sections/header/_index'
import NaoAdaptou from '../../experiencia/sections/formNaoAdaptou/_index'
import HelpCenter from '../../helpCenter/_index'

import pageContext from './pageContext.json'

const ExperienciaPJ = () => {
  const page = 'PJ'
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Header page={page} />
        <NaoAdaptou page={page} />
        <HelpCenter bgColor='color-green' />
      </Layout>
    </Wrapper>
  )
}

export default ExperienciaPJ
